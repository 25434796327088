import React from 'react';
import { Theme, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(2),
    borderRadius: '1rem',
    width: '100%',
    height: '40vw',
    minHeight: '9rem',
    maxHeight: '15rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.dark,
    },
  },
  column: {
    maxWidth: '8rem',
  },
  label: {
    overflow: 'hidden',
    textAlign: 'left',
    fontSize: '1rem',
    lineHeight: '1.5',
    maxHeight: '4.5rem',
    wordBreak: 'break-word',
  },
  iconWrapper: {
    color: theme.palette.secondary.light,
    fontSize: '3rem',
    lineHeight: '3rem',
    verticalAlign: 'middle',
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      fontSize: '7rem',
      lineHeight: '6rem',
      paddingBottom: theme.spacing(3),
    },
  },
  gridItem: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  icon: {
    width: '80%',
  },
}));

const BigIconButton: React.FC<{ icon: React.ReactElement; path: string }> = ({
  children,
  icon,
  path,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={6} className={classes.gridItem}>
      <Button
        className={classes.button}
        onClick={(): Promise<void> => navigate(path)}
      >
        <Grid direction="column" container alignItems="flex-start">
          <Grid item className={classes.iconWrapper}>
            {React.cloneElement(icon, {
              fontSize: 'inherit',
            })}
          </Grid>
          <Grid item className={classes.label}>
            {children}
          </Grid>
        </Grid>
      </Button>
    </Grid>
  );
};

export default BigIconButton;
