import React from 'react';
import Layout from '../components/layout';
import BigIconButton from '../components/bigIconButton';
import { Typography, Grid, Box } from '@material-ui/core';
import Chat from '../icons/chat';
import Referral from '../icons/referral';
import Image from 'gatsby-image';
import useAppLogo from '../hooks/useAppLogo';
import TransportIcon from '../icons/transportIcon';
import Community from '../icons/community';
import Medical from '../icons/medical';
import { BaseProps } from '../typings/types';
import { makeStyles } from '@material-ui/core/styles';
import Support from '../icons/support';

import useAuthRouteProtection from '../hooks/useAuthRouteProtection';
import { useEffect } from 'react';
import { useFormContext } from '../components/formContext';
import useConfig from '../hooks/useConfig';
import {
  FORM_TYPE_NAME,
  FORM_TYPE_PAGE_URL,
  FormType,
} from '../services/lists';
import LoadingWrapper from '../components/loadingWrapper';
import { ChildrenFamilySupport, Collaboration } from '../icons';

const useStyles = makeStyles(() => ({
  centralColumn: {
    width: '16rem',
    paddingBottom: '3rem',
  },
}));

interface PureHomeProps {
  logoData: FixedObject;
  services: string[];
  gettingServices: boolean;
}

export const PureHome: React.FC<PureHomeProps> = ({
  logoData,
  services,
  gettingServices,
}) => {
  const classes = useStyles();
  const noServiceConfig = !services || services.length === 0;
  return (
    <Layout
      wrapper={false}
      navigateBackToPage={null}
      showUsername={true}
      bottomShim={true}
      justify="flex-start"
    >
      <LoadingWrapper
        loading={gettingServices}
        hasConfig={true}
        submitted={false}
        submitting={false}
        error={false}
      >
        <Image fixed={logoData} alt="Connectors" />
        <Box my={3}>
          <Typography variant="h6" align="center">
            Select support type
          </Typography>
        </Box>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid
            className={classes.centralColumn}
            xs={12}
            sm={8}
            md={6}
            lg={4}
            container
            item
            direction="row"
            justify="flex-start"
          >
            {(noServiceConfig || services.includes(FormType.Transport)) && (
              <BigIconButton
                icon={<TransportIcon />}
                path={FORM_TYPE_PAGE_URL[FormType.Transport]}
              >
                {FORM_TYPE_NAME.Transport}
              </BigIconButton>
            )}
            {(noServiceConfig || services.includes(FormType.Prevention)) && (
              <BigIconButton
                icon={<Chat />}
                path={FORM_TYPE_PAGE_URL[FormType.Prevention]}
              >
                {FORM_TYPE_NAME.Prevention}
              </BigIconButton>
            )}

            {(noServiceConfig || services.includes(FormType.Collaboration)) && (
              <BigIconButton
                icon={<Collaboration />}
                path={FORM_TYPE_PAGE_URL[FormType.Collaboration]}
              >
                {FORM_TYPE_NAME.Collaboration}
              </BigIconButton>
            )}

            {(noServiceConfig || services.includes(FormType.Referral)) && (
              <BigIconButton
                icon={<Referral />}
                path={FORM_TYPE_PAGE_URL[FormType.Referral]}
              >
                {FORM_TYPE_NAME.Referral}
              </BigIconButton>
            )}

            {(noServiceConfig || services.includes(FormType.Yes)) && (
              <BigIconButton
                icon={<Support />}
                path={FORM_TYPE_PAGE_URL[FormType.Yes]}
              >
                {FORM_TYPE_NAME.Yes}
              </BigIconButton>
            )}

            {(noServiceConfig ||
              services.includes(FormType.PlannedMedical)) && (
              <BigIconButton
                icon={<Medical />}
                path={FORM_TYPE_PAGE_URL[FormType.PlannedMedical]}
              >
                {FORM_TYPE_NAME.PlannedMedical}
              </BigIconButton>
            )}

            {(noServiceConfig || services.includes(FormType.Community)) && (
              <BigIconButton
                icon={<Community />}
                path={FORM_TYPE_PAGE_URL[FormType.Community]}
              >
                {FORM_TYPE_NAME.Community}
              </BigIconButton>
            )}

            {(noServiceConfig ||
              services.includes(FormType.ChildrenFamilySupport)) && (
              <BigIconButton
                icon={<ChildrenFamilySupport />}
                path={FORM_TYPE_PAGE_URL[FormType.ChildrenFamilySupport]}
              >
                {FORM_TYPE_NAME.ChildrenFamilySupport}
              </BigIconButton>
            )}
          </Grid>
        </Grid>
      </LoadingWrapper>
    </Layout>
  );
};
const Home: React.FC<BaseProps> = ({ location }) => {
  const pathname = location.pathname;
  const { clearForm } = useFormContext();
  const { config, gettingConfig } = useConfig();
  const services =
    config && config.items[FormType.Home]
      ? config.items[FormType.Home].services
      : [];

  useEffect(() => {
    // Don't let stale form data survive
    clearForm();
  }, []);
  useAuthRouteProtection(pathname);
  const logoData = useAppLogo();
  return (
    <PureHome
      logoData={logoData}
      services={services || []}
      gettingServices={gettingConfig}
    />
  );
};

export default Home;
